import BaseSvg from '@components/base/svg/svg';

import type { RaiseCapitalPostInfoContent } from '@models/sections';
import './mining-info.scss';

interface Props {
  data: RaiseCapitalPostInfoContent;
}

function MiningInfo({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const infoList = data.map(({title, text, imgLarge, imgMedium, imgSmall}) => (
    <li className='mining-info__item' key={title}>
      <div className='mining-info__item-main'>
        <h3 className='mining-info__item-title' dangerouslySetInnerHTML={{__html: title}}></h3>
        {/* <BaseSvg name={icon} /> */}
        {
          imgLarge &&
          <picture className='mining-info__image'>
            {
              typeof imgMedium === 'object' &&
              <source srcSet={imgMedium.url}
                type='image/webp'
                media='(min-width: 541px) and (max-width: 1024px)'
              />
            }
            {
              typeof imgSmall === 'object' &&
              <source srcSet={imgSmall.url}
                type='image/webp'
                media='(min-width: 320px) and (max-width: 540px)'
              />
            }
            {
              typeof imgLarge === 'object' &&
              <img
                src={imgLarge.url}
                alt={imgLarge.alt}
              />
            }
          </picture>
        }
      </div>
      <div className='mining-info__item-description' dangerouslySetInnerHTML={{__html: text}}></div>
    </li>
  ));

  return (
    <section className='mining-info'>
      <div className='mining-info__inner'>
        <ul className='mining-info__list'>
          { infoList }
        </ul>
      </div>
    </section>
  );
}

export default MiningInfo;
