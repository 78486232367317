import BaseSvg from '@components/base/svg/svg';

import type { ServicesContent } from '@models/sections';
import './services.scss';

interface Props {
  data: ServicesContent;
}

function Services({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, imgLarge, imgMedium, imgSmall, servicesList } = data;

  const servicesContent = servicesList.map((service) => (
    <li key={service.title}>
      <div className='service'>
        {
          service.icon &&
          <picture className='service__icon'>
            {
              typeof service.icon === 'object' &&
              <img
                className="icon"
                src={service.icon.url}
                alt={service.icon.alt}
              />
            }
          </picture>
        }
        <h3 className='service__title' dangerouslySetInnerHTML={{ __html: service.title }}></h3>
        <div className='service__content'>
          <div className='service__text' dangerouslySetInnerHTML={{ __html: service.text }}></div>
          {
            service.list &&
            <div className="service__list-wrap">
              {
                service.listTitle &&
                  <h4 className='service__list-title' dangerouslySetInnerHTML={{ __html: service.listTitle }}></h4>
              }
              <ul className='service__list'>
                {
                  service.list.map((item) => (
                    <li key={item.title}>
                      <div className='service__list-item'>
                        <BaseSvg name='checkmark' />
                        <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                        {
                          item.tooltip &&
                          <div className={'service-info'}>
                            <span className={'service-info__btn'}>
                              <BaseSvg name={'info'} />
                            </span>
                            <div className={'service-info__content'} dangerouslySetInnerHTML={{ __html: item.tooltip }} />
                          </div>
                        }
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          }
        </div>
      </div>
    </li>
  ));

  return (
    <section className='services'>
      <div className='services__bg'></div>
      <div className='services__inner'>
        <div className='services__top'>
          <h2 className='h2 services__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
          {
            imgLarge &&
            <picture className='services__img-wrap'>
              {/* {
                typeof imgMedium === 'object' &&
                <source srcSet={imgMedium.url}
                  type='image/webp'
                  media='(min-width: 541px) and (max-width: 1024px)'
                />
              }
              {
                typeof imgSmall === 'object' &&
                <source srcSet={imgSmall.url}
                  type='image/webp'
                  media='(min-width: 320px) and (max-width: 540px)'
                />
              } */}
              {
                typeof imgLarge === 'object' &&
                <img
                  src={imgLarge.url}
                  alt={imgLarge.alt}
                />
              }
            </picture>
          }
        </div>
        <ul className='services__list'>
          {servicesContent}
        </ul>
      </div>
    </section>
  );
}

export default Services;
