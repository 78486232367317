import classNames from 'classnames';

import type { OpportunitiesContent } from '@models/sections';
import './opportunities.scss';

interface Props {
  data: OpportunitiesContent;
}

function Opportunities({ data }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title, text, cards } = data;

  const classes = classNames(
    'opportunities',
    /* {'opportunities--secondary': secondary} */
  );

  const opportunitiesList = (cards && Array.isArray(cards)) && cards?.map((card) => (
    <li className='opportunities__item' key={card.title}>
      {
        card.imgLarge &&
        <picture className='opportunities__item-image'>
          {/* {
            typeof imgMedium === 'object' &&
            <source srcSet={imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof imgSmall === 'object' &&
            <source srcSet={imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          } */}
          {
            typeof card.imgLarge === 'object' &&
            <img
              src={card.imgLarge.url}
              alt={card.imgLarge.alt}
            />
          }
        </picture>
      }
      <h3 className='h3 opportunities__item-title' dangerouslySetInnerHTML={{__html: card.title}}></h3>
      <p className='opportunities__item-text' dangerouslySetInnerHTML={{__html: card.text}} >
      </p>
    </li>
  ));

  return (
    <section className={classes}>
      <div className='opportunities__inner'>
        <div className='opportunities__top'>
          <h2 className='h2 opportunities__title' dangerouslySetInnerHTML={{__html: title}}></h2>
          <div className='opportunities__description' dangerouslySetInnerHTML={{__html: text}}></div>
          {/* { descriptionAdditional && <p className='opportunities__description-additional'>{ descriptionAdditional }</p>} */}
        </div>
        <ul className='opportunities__list'>
          { opportunitiesList }
        </ul>
      </div>
    </section>
  );
}

export default Opportunities;
