import BaseSvg from '@components/base/svg/svg';

import type { PrivilegesCard } from '@models/priveleges-card';
import './privilege.scss';

interface Props {
  data: PrivilegesCard;
}

function Privilege({ data }: Props): JSX.Element {

  const { title, text, listTitle, list, cards } = data;

  const privelegeList = Array.isArray(list) ? list.map((item) => (
    <li key={item.title}>
      <div className='privilege__content-item'>
        <BaseSvg name='checkmark' />
        <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
        {
          item.tooltip &&
          <div className={'privilege-info'}>
            <span className={'privilege-info__btn'}>
              <BaseSvg name={'info'} />
            </span>
            <div className={'privilege-info__content'} dangerouslySetInnerHTML={{ __html: item.tooltip }} />
          </div>
        }
      </div>
    </li>
  )) : null;

  const cardList = Array.isArray(cards) ? cards.map((card) => (
    <li key={card.text}>
      <div className='privilege__content-card'>
        {/* <BaseSvg name='security-shield' /> */}
        {
          card.imgLarge &&
          <picture className='mining-main__image'>
            {
              typeof card.imgMedium === 'object' &&
              <source srcSet={card.imgMedium.url}
                type='image/webp'
                media='(min-width: 541px) and (max-width: 1024px)'
              />
            }
            {
              typeof card.imgSmall === 'object' &&
              <source srcSet={card.imgSmall.url}
                type='image/webp'
                media='(min-width: 320px) and (max-width: 540px)'
              />
            }
            {
              typeof card.imgLarge === 'object' &&
              <img
                src={card.imgLarge.url}
                alt={card.imgLarge.alt}
              />
            }
          </picture>
        }
        <div className='privilege__content-text' dangerouslySetInnerHTML={{ __html: card.text }}></div>
      </div>
    </li>
  )) : null;

  return (
    <div className='privilege'>
      <h3 className='privilege__title' dangerouslySetInnerHTML={{ __html: title }}></h3>
      <div className='privilege__content'>
        {
          text &&
          <div className='privilege__content-text' dangerouslySetInnerHTML={{ __html: text }}></div>
        }
        {
          listTitle &&
          <h4 className='privilege__content-title' dangerouslySetInnerHTML={{ __html: listTitle }}></h4>
        }
        {
          list &&
            <ul className='privilege__content-list'>
              { privelegeList }
            </ul>
        }
        {
          cards &&
          <ul className='privilege__content-cards'>
            {cardList}
          </ul>
        }
      </div>
    </div>
  );
}

export default Privilege;
