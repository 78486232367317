import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { AnalyticsContent, OpportunitiesContent, BannerLinkContent, PageLinksContent } from '@models/sections';

interface Data {
  analytics: AnalyticsContent;
  opportunities: OpportunitiesContent;
  bannerLink: BannerLinkContent;
  links: PageLinksContent;
}

interface Context {
  item: Data;
  getPrivateEquityPageData: () => Promise<void>;
}

const PrivateEquityContext = createContext<Context>({
  item: {
    analytics: null,
    opportunities: null,
    bannerLink: null,
    links: null
  },
  getPrivateEquityPageData: async () => {
    await new Promise((resolve) => resolve);
    console.log('Данные страницы PrivateEquity не загружены!');
  },
});

export const usePrivateEquityStore = () => useContext(PrivateEquityContext);

export function PrivateEquityProvider({ children }: PropsWithChildren<JSX.Element>) {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    analytics: null,
    opportunities: null,
    bannerLink: null,
    links: null
  });

  async function getPrivateEquityPageData() {
    setIsLoading(true);
    await apiFetch('/wp-json/wp/v2/pages?slug=private-equity')
      .then((data) => {
        const newData: Data = {
          analytics: (data as any)[0].acf.analytics,
          opportunities: (data as any)[0].acf.opportunities,
          bannerLink: (data as any)[0].acf.feedback,
          links: (data as any)[0].acf.links
        };

        setItem(newData);
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getPrivateEquityPageData();
  }, []);

  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <PrivateEquityContext.Provider value={{ item, getPrivateEquityPageData }}>
      {isLoading ? (<div>Загрузка...</div>) : children}
    </PrivateEquityContext.Provider>
  );
}
