import { useState, useEffect } from 'react';

import { StoryContent } from '@models/sections';
import './story.scss';

interface Props {
  data: StoryContent;
}

function Story({ data }: Props): JSX.Element {

  const [storyCards, setStoryCards] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if(data) {
      const { cards } = data;
      if (cards && Array.isArray(cards) && cards.length > 0) {
        const storyList = cards?.map((card) => (
          <li key={card.title} className='story__bottom-item'>
            <h3 className="h3 story__bottom-title" dangerouslySetInnerHTML={{ __html: card.title }}></h3>
            <p className="story__bottom-description" dangerouslySetInnerHTML={{ __html: card.text }}></p>
          </li>
        ));
        setStoryCards(storyList);
      }
    }
  }, [data]);

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, text, imgLarge } = data;

  return (
    <section className='story'>
      {
        imgLarge &&
        <picture>
          {/* {
            typeof imgMedium === 'object' &&
            <source srcSet={imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof imgSmall === 'object' &&
            <source srcSet={imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          } */}
          {
            typeof imgLarge === 'object' &&
            <img
              className='promo__image'
              src={imgLarge.url}
              alt={imgLarge.alt}
            />
          }
        </picture>
      }
      <div className='story__inner'>
        <h1 className="h1 story__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
        <p className="story__description" dangerouslySetInnerHTML={{ __html: text }}></p>
        <ul className="story__bottom">
          { storyCards }
        </ul>
      </div>
    </section>
  );
}

export default Story;
