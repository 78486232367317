import BaseButton from '@components/base/button/button';
import BaseSvg from '@components/base/svg/svg';

import { NextContent } from '@models/sections';
import './next.scss';


interface Props {
  data: NextContent;
}


function Next({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, text, linkName, linkUrl, tooltip } = data;

  return (
    <section className='next'>
      <div className={'next__bg'}/>
      <div className='next__inner'>
        <h2 className='h2 next__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <div className={'next__text'} dangerouslySetInnerHTML={{ __html: text }}/>
        <div className={'next__bottom'}>
          <BaseButton to={linkUrl} classes='menu__action-item' primary outlink>
            <span>{linkName}</span>
          </BaseButton>

          <div className={'next-info'}>
            <span className={'next-info__btn'}>
              <BaseSvg name={'info'} />
            </span>
            <div className={'next-info__content'} dangerouslySetInnerHTML={{__html: tooltip}}/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Next;
