import NewsLinks from '@components/blocks/news-links/news-links';
import Insights from '@components/blocks/insights/insights';
import Questions from '@components/blocks/questions/questions';

import { useResourcesStore } from '@store/resources';

import './resources.scss';

function ResourcesPage(): JSX.Element {

  if (!useResourcesStore) {
    throw new Error('useResourcesStore must be used within a ResourcesProvider');
  }
  const { item } = useResourcesStore();

  if (!item) {
    return <div>The Resources page data has not been uploaded!</div>;
  }

  return (
    <main className='main resources-page'>
      <div className='resources-page__top'>
        <h1 className='h1 resources-page__title' dangerouslySetInnerHTML={{ __html: item.pageTitle}}></h1>
        <div className='resources-page__top-bg'></div>
      </div>
      <NewsLinks data={item.newsLinks} />
      <Insights data={item.insights} />
      <Questions data={item.faq} />
    </main>
  );
}

export default ResourcesPage;
