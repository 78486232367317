import { Outlet } from 'react-router-dom';

import Header from '../header/header';
import Footer from '../footer/footer';

import { useLayoutStore } from '@store/layout';

import './layout.scss';

function Layout(): JSX.Element {
  if (!useLayoutStore) {
    throw new Error(
      'useLayoutStore must be used within a RaiseCapitalProvider'
    );
  }
  const { item } = useLayoutStore();

  if (!item) {
    return <div>The site Header or Footer data has not been uploaded!</div>;
  }

  return (
    <>
      <Header nav={item.headerNav} />
      <Outlet />
      <Footer nav={item.footerNav} />
    </>
  );
}

export default Layout;
