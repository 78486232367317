import NewsLinkModal from '@components/blocks/modals/news-link-modal/news-link-modal';
import BaseButton from '@components/base/button/button';
import BaseSvg from '@components/base/svg/svg';

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import type { NewsLinksContent } from '@models/sections';
import { NewsLinkItem } from '@models/news-link';

import { useModal } from '@store/modals';

import NewsLink from './news-link/news-link';
import './news-links.scss';

interface Props {
  data: NewsLinksContent;
}

function NewsLinks({ data }: Props): JSX.Element {

  const { open, render } = useModal(NewsLinkModal);

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, cards } = data;

  const newsLinkList: JSX.Element[][] = cards.reduce<JSX.Element[][]>((arr, card, index) => {
    const hasModalContent = card.title !== '' && card.text !== '';
    let modalContent = {};

    if (hasModalContent) {
      modalContent = {
        title: card.title,
        text: card.text,
        imgLarge: card.imgLarge
      };
    }

    if (index % 3 === 0) {
      arr.push([]);
    }

    arr[arr.length - 1].push(
      <li key={card.titlePreview}>
        <NewsLink data={card} onClick={hasModalContent ? () => handleOpenModal(modalContent as never) : undefined} />
      </li>
    );

    return arr;
  }, []);

  const slideList = newsLinkList.map((block, index) => (
    <SwiperSlide
      tag='li'
      // eslint-disable-next-line react/no-array-index-key
      key={index}
    >
      <ul className='news-links__list'>
        { block }
      </ul>
    </SwiperSlide>
  ));

  const handleOpenModal = (modalContent: never) => {
    render(modalContent);
    open();
  };

  return (
    <section className='news-links'>
      <div className='news-links__inner'>
        <div className='news-links__top'>
          <h2 className='h2 news-links__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
          <div className='news-links__buttons'>
            <BaseButton classes='news-links__button news-links__button-prev'>
              <BaseSvg name='arrow-left' />
            </BaseButton>
            <BaseButton classes='news-links__button news-links__button-next'>
              <BaseSvg name='arrow-right' />
            </BaseButton>
          </div>
        </div>
        <Swiper
          className='news-links__slider'
          wrapperTag='ul'
          wrapperClass='news-links__slider-list'
          modules={[Pagination, Navigation]}
          navigation={
            {
              nextEl: '.news-links__button-next',
              prevEl: '.news-links__button-prev',
              disabledClass: 'news-links__button--disabled'
            }
          }
          pagination={
            {
              el: '.news-links__pagination-wrapper',
              clickable: true,
              bulletClass: 'news-links__pagination-bullet',
              bulletActiveClass: 'news-links__pagination-bullet-active'
            }
          }
          slidesPerView={1}
          spaceBetween={48}
          speed={500}
          mousewheel
        >
          { slideList }
        </Swiper>
        <div className='news-links__pagination-wrapper'/>
      </div>
    </section>
  );
}

export default NewsLinks;
