import Feedback from '@components/blocks/feedback/feedback';
import './feedback.scss';

function FeedbackPage(): JSX.Element {

  return (
    <main className='main'>
      <Feedback />
    </main>
  );
}

export default FeedbackPage;
