import type { InsightType } from '@models/insight';
import './insight.scss';

interface Props {
  data: InsightType;
  onClick: (() => void) | undefined;
}

function Insight({ data, onClick }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { titlePreview, textPreview } = data;

  return (
    <div className='insight' onClick={onClick}>
      <h3 className='insight__title' dangerouslySetInnerHTML={{ __html: titlePreview }}></h3>
      <div className='insight__text' dangerouslySetInnerHTML={{ __html: textPreview }}></div>
    </div>
  );
}

export default Insight;
