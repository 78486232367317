import BaseSvg from '@components/base/svg/svg';
import BaseButton from '@components/base/button/button';

import type { VacanciesCard } from '@models/vacancies-card';
import './vacancy.scss';

interface Props {
  data: VacanciesCard;
}

function Vacancy({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, text, listTitle, list, btns } = data;
  /* const { send, more } = actions; */

  const responsibilitiesList = list?.map((item) => (
    <li className='vacancy-card__item' key={item.title}>
      <BaseSvg name='checkmark' />
      <p dangerouslySetInnerHTML={{__html: item.title}}></p>
    </li>
  ));

  const btnList = btns?.map((btn) => {
    if (btn.link !== '' && !((typeof btn.file === 'object') && (btn.file !== null))) {
      return <BaseButton key={btn.title} to={btn.link} primary outlink>{btn.title}</BaseButton>;
    } else if ((typeof btn.file === 'object') && (btn.file !== null) && btn.link === '') {
      return <BaseButton key={btn.title} to={btn.file.url} bordered transparent outlink>{btn.title}</BaseButton>;
    } else {
      return <div key={btn.title}>Нет данных для кнопок</div>;
    }
  });

  return (
    <div className='vacancy-card'>
      <h3 className='vacancy-card__title' dangerouslySetInnerHTML={{__html: title}}></h3>
      <div className='vacancy-card__content'>
        <p className='vacancy-card__description' dangerouslySetInnerHTML={{__html: text}}></p>
        <div className='vacancy-card__list-wrap'>
          <h4 className='vacancy-card__list-title' dangerouslySetInnerHTML={{ __html: listTitle }}></h4>
          <ul className='vacancy-card__list'>
            {responsibilitiesList}
          </ul>
        </div>
      </div>
      <div className='vacancy-card__actions'>
        {/* <BaseButton to={send.href} primary outlink>Send your cv</BaseButton>
        <BaseButton to={more.href} download={more.download} bordered transparent outlink>Learn more</BaseButton> */}
        { btnList }
      </div>
    </div>
  );
}

export default Vacancy;
