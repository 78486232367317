import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useState, useEffect } from 'react';

import type { PartnersContent } from '@models/sections';
import type { PartnersCard } from '@models/partners-card';
import './partners.scss';

interface Props {
  data: PartnersContent;
}

function Partners({ data }: Props): JSX.Element {

  const [brandName, setBrandName] = useState<string>('');
  const [brandText, setBrandText] = useState<string>('');
  const [brandImage, setBrandImage] = useState<string>('');

  useEffect(() => {
    if (data && data.cards.length > 0) {
      const { cards } = data;
      setBrandName(cards[0].title);
      setBrandText(cards[0].text);
      typeof cards[0].imgLarge === 'object' && setBrandImage(cards[0].imgLarge.url);
    }
  }, [data]);

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, cards } = data;

  const brandsList = cards.map((item) => (
    <SwiperSlide
      tag='li'
      key={item.title}
    >
      {
        typeof item.imgLarge === 'object' &&
        <picture className={`partners__item ${brandName === item.title ? 'active' : ''}`} onClick={() => handleClick(item)}>
          <img src={item.imgLarge.url} alt={item.imgLarge.title} />
        </picture>
      }
    </SwiperSlide>
  ));

  function handleClick(brand: PartnersCard) {
    setBrandName(brand.title);
    setBrandText(brand.text);
    typeof brand.imgLarge === 'object' && setBrandImage(brand.imgLarge.url);
  }

  return (
    <section className="partners">
      <div className="partners__inner">
        <h2 className="h2 partners__title" dangerouslySetInnerHTML={{ __html: title }}></h2>
        <div className="partners__top">
          <div className="partners__info">
            <h3 className="partners__info-title" dangerouslySetInnerHTML={{ __html: brandName }}></h3>
            <p className="partners__info-text" dangerouslySetInnerHTML={{__html: brandText}}></p>
          </div>
          <picture className="partners__image">
            <img src={brandImage} alt={brandName} />
          </picture>
        </div>
        <Swiper
          className='partners__bottom'
          wrapperTag='ul'
          wrapperClass='partners__list'
          slidesPerView={8}
          spaceBetween={42}
          speed={500}
          mousewheel
          breakpoints={{
            320: {
              slidesPerView: 2.5,
            },
            641: {
              slidesPerView: 4,
            },
            1025: {
              slidesPerView: 5.5
            },
            1281: {
              slidesPerView: 7
            }
          }}
        >
          {brandsList}
        </Swiper>
      </div>
    </section>
  );
}

export default Partners;
