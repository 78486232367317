import React, { createContext, useContext, useState, useEffect, useCallback, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { RaiseCapitalPostMainContent, RaiseCapitalPostInfoContent, PageLinksContent, BannerLinkContent } from '@models/sections';
import { useNavigate, useParams } from 'react-router-dom';

interface Data {
    main: RaiseCapitalPostMainContent;
    info: RaiseCapitalPostInfoContent;
    links: PageLinksContent;
    bannerLink: BannerLinkContent;
}

interface Context {
  item: Data;
  getRaiseCapitalPostData: () => Promise<void>;
}

interface RaiseCapitalPostStore {
  children: PropsWithChildren<JSX.Element>;
}

const RaiseCapitalPostContext = createContext<Context>({
  item: {
    main: null,
    info: [],
    links: null,
    bannerLink: null
  },
  getRaiseCapitalPostData: async () => {
    await new Promise((resolve) => resolve);
    console.log('The Raise Capital post data has not been uploaded!');
  },
});

export const useRaiseCapitalPostStore = () => useContext(RaiseCapitalPostContext);

export function RaiseCapitalPostProvider({ children }: RaiseCapitalPostStore) {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    main: null,
    info: [],
    links: null,
    bannerLink: null
  });

  async function getRaiseCapitalPostData() {
    if (!slug) {
      console.log('Undefined post');
      return;
    }
    setIsLoading(true);
    await apiFetch(`/wp-json/wp/v2/posts/?slug=${slug}`)
      .then((data) => {
        if ((data as any).length === 0) {
          // Если данные отсутствуют, перенаправляем на /development
          navigate('/*');
        }

        const newData: Data = {
          main: (data as any)[0].acf.banner,
          info: (data as any)[0].acf.advantages,
          links: (data as any)[0].acf.links,
          bannerLink: (data as any)[0].acf.feedback,
        };

        setItem(newData);
      })
      .catch((error) => {
        console.error('Error when uploading data:', error);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    getRaiseCapitalPostData();
  }, [slug]);

  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <RaiseCapitalPostContext.Provider value={{ item, getRaiseCapitalPostData }}>
      {isLoading ? (<div>Loading...</div>) : children}
    </RaiseCapitalPostContext.Provider>
  );
}