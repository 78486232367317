import CareersMain from '@components/blocks/careers-main/careers-main';
import Vacancies from '@components/blocks/vacancies/vacancies';
import BannerLink from '@components/blocks/banner-link/banner-link';

import { useCareersStore } from '@store/careers';

import './careers.scss';

function CareersPage(): JSX.Element {

  if (!useCareersStore) {
    throw new Error('useCareersStore must be used within an CareersProvider');
  }

  const { item } = useCareersStore();

  if (!item) {
    return <div>The Careers page data has not been uploaded!</div>;
  }

  return (
    <main className='main'>
      <CareersMain data={item.main} />
      <Vacancies data={item.vacancies} />
      <BannerLink data={item.bannerLink} />
    </main>
  );
}

export default CareersPage;
