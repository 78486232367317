import BaseButton from '@components/base/button/button';
import { developmentMain } from '@data';

import './development.scss';

function DevelopmentMain(): JSX.Element {

  return (
    <section className='development'>
      <div className='development__inner'>
        <h1 className='visually-hidden'>The page is under development</h1>
        <h2 className="h2 development__title">The page is under development</h2>
        <BaseButton to={'/'} classes='development__btn' inlink primary>
          <span>Go back to the main page</span>
        </BaseButton>
      </div>
    </section>
  );
}

export default DevelopmentMain;
