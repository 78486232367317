import classNames from 'classnames';

import BaseButton from '@components/base/button/button';

import type { BannerLinkContent } from '@models/sections';
import './banner-link.scss';

interface Props {
  data: BannerLinkContent;
}

function BannerLink({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, text, linkName, linkUrl, imgLarge, imgMedium, imgSmall } = data;

  const bannerLinkClasses = classNames(
    'banner-link',
    {
      'banner-link--secondary': !(imgLarge || imgMedium || imgSmall)
    }
  );

  return (
    <section className={bannerLinkClasses}>
      <div className="banner-link__inner">
        <div className="banner-link__top">
          <h2 className='h2 banner-link__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
          {text && (
            <p className="banner-link__text" dangerouslySetInnerHTML={{ __html: text }}></p>
          )}
        </div>
        <BaseButton to={linkUrl} classes='banner-link__button' outlink primary>
          <span>{ linkName }</span>
        </BaseButton>
      </div>
      {
        imgLarge &&
        <picture className='banner-link__image'>
          {
            typeof imgMedium === 'object' &&
            <source srcSet={imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof imgSmall === 'object' &&
            <source srcSet={imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          }
          {
            typeof imgLarge === 'object' &&
            <img
              src={imgLarge.url}
              alt={imgLarge.alt}
            />
          }
        </picture>
      }
    </section>
  );
}

export default BannerLink;
