import { Link } from 'react-router-dom';
import classNames from 'classnames';

import BaseSvg from '@components/base/svg/svg';

import { AbstractContent } from '@models/sections';

import './abstract.scss';

interface Props {
  data: AbstractContent;
}

function Abstract({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, cards } = data;

  const iconClasses = classNames(
    'abstract__item-icon'
  );

  const sectionClasses = classNames(
    'abstract',
    /* { 'abstract--secondary': data.secondary } */
  );

  const abstractContent = cards?.map((item) => (
    <li className={'abstract__list-item'} key={item.title}>
      <div className='abstract__item'>
        <div className='abstract__item-inner'>
          <Link
            className='abstract__item-top'
            to={`/raise-capital${item.link}`}
          >
            <h3 className='h3 abstract__item-title'>{item.title}</h3>
            {item.link && <BaseSvg name={'arrow-right-top'} classes={iconClasses} />}
          </Link>
          {item.text && <p className='abstract__item-text' dangerouslySetInnerHTML={{ __html: item.text }} />}
        </div>
      </div>
    </li>
  ));

  return (
    <section className={sectionClasses}>
      {/* <div className={'abstract__bg'}/> */}
      <div className='abstract__inner'>
        <h2 className='h2 abstract__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <div className='abstract__content'>
          <ul className='abstract__list'>
            { abstractContent }
          </ul>
        </div>
      </div>
      {/* {
        imgLarge &&
        <picture>
          {
            typeof imgMedium === 'object' &&
            <source srcSet={imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof imgSmall === 'object' &&
            <source srcSet={imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          }
          {
            typeof imgLarge === 'object' &&
            <img
              className='abstract__image'
              src={imgLarge.url}
              alt={imgLarge.alt}
            />
          }
        </picture>
      } */}
    </section>
  );
}

export default Abstract;
