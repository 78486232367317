import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Commodities from '@components/blocks/commodities/commodities';
import Advantages from '@components/blocks/advantages/advantages';
import BannerLink from '@components/blocks/banner-link/banner-link';
import PageLinks from '@components/blocks/page-link/page-links';

import { useCommoditiesStore } from '@store/commodities';

import type { BreadcrumbsItem } from '@types';
import './commodities.scss';

function CommoditiesPage(): JSX.Element {

  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Commodities' }];

  if (!useCommoditiesStore) {
    throw new Error('useCommoditiesStore must be used within an CommoditiesProvider');
  }

  const { item } = useCommoditiesStore();

  if (!item) {
    return <div>Данные страницы Commodities не загружены!</div>;
  }

  return (
    <main className='main commodities-page'>
      <Breadcrumbs breadcrumbs={breadcrumbsList}/>
      <Commodities data={item.commodities} />
      <Advantages data={item.advantages} />
      <PageLinks links={item.links} />
      <BannerLink data={item.bannerLink} />
    </main>
  );
}

export default CommoditiesPage;
