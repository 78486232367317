import BaseButton from '@components/base/button/button';
import { developmentMain } from '@data';

import './not-found.scss';

function NotFoundMain(): JSX.Element {

  return (
    <section className='not-found'>
      <div className='not-found__inner'>
        <h1 className='visually-hidden'>404 Not Found</h1>
        <h2 className="h2 not-found__title">404 Not Found</h2>
        <BaseButton to={'/'} classes='not-found__btn' inlink primary>
          <span>Go back to the main page</span>
        </BaseButton>
      </div>
    </section>
  );
}

export default NotFoundMain;