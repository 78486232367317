import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { HeaderNavContent, HeaderNavItem, HeaderNavSubItem, FooterNavContent, FooterNavSubItem, FooterNavItem } from '@models/layout';

interface Data {
    headerNav: HeaderNavContent;
    footerNav: FooterNavContent;
}

interface Context {
  item: Data;
  getHeaderMenuData: () => Promise<void>;
  getFooterMenuData: () => Promise<void>;
}

/*  */
interface ChildItem {
  title: string;
  url: string;
}

interface MenuItem {
  title: string;
  url: string;
  child_items: ChildItem[];
}

interface ApiResponse {
  items: MenuItem[];
}
/*  */

const LayoutContext = createContext<Context>({
  item: {
    headerNav: [],
    footerNav: []
  },
  getHeaderMenuData: async () => {
    await new Promise((resolve) => resolve);
    console.log('The site Header data has not been uploaded!');
  },
  getFooterMenuData: async () => {
    await new Promise((resolve) => resolve);
    console.log('The site Footer data has not been uploaded!');
  },
});

export const useLayoutStore = () => useContext(LayoutContext);

export function LayoutProvider({ children }: PropsWithChildren<JSX.Element>) {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    headerNav: [],
    footerNav: []
  });

  async function getHeaderMenuData() {
    setIsLoading(true);
    await apiFetch('/wp-json/menus/v1/menus/menu-header')
      .then((data) => {
        const newData: {
          title: string;
          link: string;
          list?: HeaderNavSubItem[];
        }[] = [];

        (data as ApiResponse).items.forEach((dItem) => {

          const itemList: HeaderNavSubItem[] = [];

          dItem.child_items?.forEach((subitem) => {
            itemList.push({
              title: subitem.title,
              link: subitem.url
            });
          });

          const itemNav: HeaderNavItem = {
            title: dItem.title,
            link: dItem.url
          };

          if (itemList && itemList.length > 0) {
            itemNav.list = itemList;
          }

          newData.push(itemNav);
        });

        setItem((prevState) => ({
          ...prevState,
          headerNav: newData,
        }));
      })
      .catch((error) => {
        console.error('Error when uploading data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function getFooterMenuData() {
    setIsLoading(true);
    await apiFetch('/wp-json/menus/v1/menus/footer-menu')
      .then((data) => {
        const newData: {
          title: string;
          link: string;
          list?: FooterNavSubItem[];
        }[] = [];

        (data as ApiResponse).items.forEach((dItem) => {

          const itemList: FooterNavSubItem[] = [];

          dItem.child_items?.forEach((subitem) => {
            itemList.push({
              title: subitem.title,
              link: subitem.url
            });
          });

          const itemNav: FooterNavItem = {
            title: dItem.title,
            link: dItem.url,
          };

          if (itemList && itemList.length > 0) {
            itemNav.list = itemList;
          }

          newData.push(itemNav);
        });

        setItem((prevState) => ({
          ...prevState,
          footerNav: newData,
        }));
      })
      .catch((error) => {
        console.error('Error when uploading data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getHeaderMenuData();
    getFooterMenuData();
  }, []);

  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <LayoutContext.Provider value={{ item, getHeaderMenuData, getFooterMenuData }}>
      {isLoading ? (<div>Loading...</div>) : children}
    </LayoutContext.Provider>
  );
}
