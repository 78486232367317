import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Story from '@components/blocks/story/story';
import Directors from '@components/blocks/directors/directors';
import Staff from '@components/blocks/staff/staff';

import { useAboutStore } from '@store/about';
import type { BreadcrumbsItem } from '@types';

import './about.scss';

function AboutPage(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'About Us' }];

  if (!useAboutStore) {
    throw new Error('useAboutStore must be used within an AboutProvider');
  }

  const { item } = useAboutStore();

  if (!item) {
    return <div>The About Us page data has not been uploaded!</div>;
  }

  return (
    <main className='main'>
      <Breadcrumbs breadcrumbs={breadcrumbsList} />
      {/* <h1 className='about__title'>About Us</h1> */}
      <Story data={item.story}/>
      {/* <Directors directors={directors}/> */}
      <Staff data={item.staff}/>
    </main>
  );
}

export default AboutPage;
