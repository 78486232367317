import Modal from '@components/base/modal/modal';

import type { InsightModalContent } from '@models/insight';
import './insight-modal.scss';

interface Props {
  data: InsightModalContent;
  isOpened: boolean;
  onClose: () => void;
}

function InsightModal({ data, isOpened, onClose }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { imgLarge, title, text } = data;

  return (
    <Modal isOpened={isOpened} onClose={onClose}>
      <article className='m-insight'>
        <p className='m-insight__title' dangerouslySetInnerHTML={{ __html: title }} ></p>
        <div className='m-insight__info'>
          {
            imgLarge &&
            <picture className='m-insight__image'>
              {/* {
                typeof imgMedium === 'object' &&
                <source srcSet={imgMedium.url}
                  type='image/webp'
                  media='(min-width: 541px) and (max-width: 1024px)'
                />
              }
              {
                typeof imgSmall === 'object' &&
                <source srcSet={imgSmall.url}
                  type='image/webp'
                  media='(min-width: 320px) and (max-width: 540px)'
                />
              } */}
              {
                typeof imgLarge === 'object' &&
                <img
                  src={imgLarge.url}
                  alt={'m-insight__image'}
                />
              }
            </picture>
          }
          {/* <video src='/img/resources/popup-img.png' className='m-insight__video'></video> */}
          <div className='m-insight__text-wrap'>
            <div className='m-insight__text' dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </div>
      </article>
    </Modal>
  );
}

export default InsightModal;
