import React, { createContext, PropsWithChildren, ComponentType, useContext, useState } from 'react';

interface ModalProps {
  data: never;
  isOpened: boolean;
  onClose: () => void;
}

interface Context {
  openModal: () => void;
  closeModal: () => void;
  renderModal: (ModalComponent: ComponentType<ModalProps>, data: never) => void;
}

const ModalsContext = createContext<Context>({
  openModal: () => console.log('There is not Modal component'),
  closeModal: () => console.log('There is not Modal component'),
  renderModal: (ModalComponent: ComponentType<ModalProps>, data: never) => console.log('Нет модального компонента')
});

export const ModalsProvider = ({ children }: PropsWithChildren<JSX.Element>) => {
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [ModalToRender, setModalToRender] = useState<ComponentType<ModalProps>>();
  const [modalData, setModalData] = useState<never>();

  function openModal() {
    console.log('opened');
    setOpenedModal(true);
  }

  function closeModal() {
    console.log('closed');
    setOpenedModal(false);
  }

  function renderModal(ModalComponent: ComponentType<ModalProps>, data: never) {
    setModalToRender(() => ModalComponent);
    setModalData(data);
  }

  return (
    <ModalsContext.Provider value={{ openModal, closeModal, renderModal }}>
      {children}
      {
        ModalToRender &&
        <ModalToRender
          data={modalData as never}
          isOpened={openedModal}
          onClose={closeModal}
        />
      }
    </ModalsContext.Provider>
  );
};


// Обновлённый хук useModal
export const useModal = (ModalComponent: ComponentType<ModalProps>) => {
  const { openModal, closeModal, renderModal } = useContext(ModalsContext);

  const open = () => openModal();
  const close = () => closeModal();

  function render(data: never) {
    renderModal(ModalComponent, data);
  }

  return { open, close, render };
};
