import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { BannerContent, MeansContent, IssuanceContent } from '@models/sections';

interface Data {
    banner: BannerContent;
    means: MeansContent;
    issuance: IssuanceContent;
}

interface Context {
  item: Data;
  getHowItWorksPageData: () => Promise<void>;
}

const HowItWorksContext = createContext<Context>({
  item: {
    banner: null,
    means: null,
    issuance: null
  },
  getHowItWorksPageData: async () => {
    await new Promise((resolve) => resolve);
    console.log('The How it works page data has not been uploaded!');
  },
});

export const useHowItWorksStore = () => useContext(HowItWorksContext);

export function HowItWorksProvider({ children }: PropsWithChildren<JSX.Element>) {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    banner: null,
    means: null,
    issuance: null
  });

  async function getHowItWorksPageData() {
    setIsLoading(true);
    await apiFetch('/wp-json/wp/v2/pages?slug=how-it-works')
      .then((data) => {
        const newData: Data = {
          banner: (data as any)[0].acf.banner,
          means: (data as any)[0].acf.means,
          issuance: (data as any)[0].acf.issuance,
        };

        setItem(newData);
      })
      .catch((error) => {
        console.error('Error when uploading data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getHowItWorksPageData();
  }, []);

  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <HowItWorksContext.Provider value={{ item, getHowItWorksPageData }}>
      {isLoading ? (<div>Loading...</div>) : children}
    </HowItWorksContext.Provider>
  );
}