import { useState, type PropsWithChildren, useEffect } from 'react';
import BaseButton from '@components/base/button/button';
import BaseSvg from '@components/base/svg/svg';

import './popover.scss';

type PopoverProps = {
  isOpened: boolean;
  onClose: () => void;
};

function Popover(props: PropsWithChildren<PopoverProps>): JSX.Element {
  const {
    children,
    isOpened,
    onClose
  } = props;

  const [popoverState, setPopoverState] = useState<boolean>(isOpened);

  useEffect(() => {
    setPopoverState(isOpened);
  }, [isOpened]);

  const onCloseClick = (): void => {
    setPopoverState(false);
    onClose();
  };

  return (
    <div className={`popover ${popoverState ? '_opened' : ''}`}>
      <div className='popover__inner'>
        <BaseButton
          classes='popover__close'
          onClick={onCloseClick}
        >
          <BaseSvg name='close'/>
        </BaseButton>
        {children}
      </div>
      <div className='popover__overlay' onClick={onCloseClick}></div>
    </div>
  )
}

export default Popover;
