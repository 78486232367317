import NotFoundMain from '@components/blocks/not-found/not-found';

import './not-found.scss';

function NotFoundPage(): JSX.Element {

  return (
    <main className='main'>
      <NotFoundMain />
    </main>
  );
}

export default NotFoundPage;
