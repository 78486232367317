import BaseSvg from '@components/base/svg/svg';

import classNames from 'classnames';

import type { NewsLinkItem } from '@models/news-link';
import './news-link.scss';

interface Props {
  data: NewsLinkItem;
  onClick: (() => void) | undefined;
}

function NewsLink({ data, onClick }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { titlePreview, textPreview, date, company } = data;

  const newsLinkTopClasses = classNames(
    'news-link__top',
    {
      'news-link__top--active': onClick
    }
  );

  return (
    <div className='news-link'>
      <button
        className={newsLinkTopClasses}
        onClick={onClick}
      >
        <h3 className='news-link__title' dangerouslySetInnerHTML={{ __html: titlePreview }}></h3>
        {
          onClick && <BaseSvg name='arrow-right' />
        }
      </button>
      <div className='news-link__text' dangerouslySetInnerHTML={{ __html: textPreview }}>
      </div>
      <div className='news-link__bottom'>
        <span className='news-link__date'>{date}</span>
        { company && <span className='news-link__company'>{company}</span>}
      </div>
    </div>
  );
}

export default NewsLink;
