import Modal from '@components/base/modal/modal';

import type { StaffCardModalContent } from '@models/staff-card';
import './staff-modal.scss';

interface Props {
  data: StaffCardModalContent;
  isOpened: boolean;
  onClose: () => void;
}

function StaffCardModal({ data, isOpened, onClose }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { imgLarge, title, subTitle, detailText } = data;

  return (
    <Modal isOpened={isOpened} onClose={onClose}>
      <article className='m-staff'>
        {
          imgLarge &&
            <picture className='m-staff__image'>
              {/* {
                typeof imgMedium === 'object' &&
                <source srcSet={imgMedium.url}
                type='image/webp'
                media='(min-width: 541px) and (max-width: 1024px)'
                />
            }
            {
                typeof imgSmall === 'object' &&
                <source srcSet={imgSmall.url}
                type='image/webp'
                media='(min-width: 320px) and (max-width: 540px)'
                />
            } */}
              {
                typeof imgLarge === 'object' &&
                <img
                  src={imgLarge.url}
                  alt={imgLarge.alt}
                />
              }
            </picture>
        }
        <div className='m-staff__info'>
          <span className='m-staff__position' dangerouslySetInnerHTML={{ __html: subTitle }}></span>
          <h4 className='h4 m-staff__name' dangerouslySetInnerHTML={{ __html: title }}></h4>
          {
            detailText &&
                <div className='m-staff__description' dangerouslySetInnerHTML={{ __html: detailText }} />
          }
        </div>
      </article>
    </Modal>
  );
}

export default StaffCardModal;
