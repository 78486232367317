import QuestionsBlock from './block/questions-block';

import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import type { QuestionsNavType, QuestionsBlockType } from '@models/question';
import type { QuestionsContent } from '@models/sections';
import './questions.scss';

interface Props {
  data: QuestionsContent;
}

function Questions({ data }: Props): JSX.Element {

  const [questionsNavList, setQuestionsNavList] = useState<QuestionsNavType[] | null>(null);
  const [blocks, setBlocks] = useState<QuestionsBlockType[] | null>(null);
  const [currentBlock, setCurrentBlock] = useState<QuestionsBlockType | null>(null);

  const menuRef = useRef<HTMLUListElement | null>(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    if (data) {
      const { cards } = data;
      if (cards && Array.isArray(cards) && cards.length > 0) {
        const navList = cards.map((card, index) => ({
          title: card.title,
          active: index === 0
        }));
        setQuestionsNavList(navList);
        setBlocks(cards);
        setCurrentBlock(cards[0]);
      }
    }
  }, [data]);

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title } = data;

  const insightsNavList = questionsNavList?.map((navItem, index) => {
    const navItemClasses = classNames(
      'questions__nav-item',
      { 'questions__nav-item--active': navItem.active }
    );
    return (
      <li key={navItem.title}>
        <p className={navItemClasses} onClick={() => handleNavClick(index)} dangerouslySetInnerHTML={{ __html: navItem.title }}></p>
      </li>
    );
  });

  const handleNavClick = (id: number) => {
    const updatedNav = questionsNavList?.map((navItem, index) => ({
      ...navItem,
      active: index === id
    }));
    updatedNav && setQuestionsNavList(updatedNav);

    const targetBlock = blocks?.find((block, blockId) => blockId === id);
    targetBlock && setCurrentBlock(targetBlock);
  };

  const mouseDownHandler = (e: React.MouseEvent<HTMLUListElement>) => {
    if (menuRef.current) {
      setIsDown(true);
      menuRef.current.classList.add('active');
      setStartX(e.pageX - menuRef.current.offsetLeft);
      setScrollLeft(menuRef.current.scrollLeft);
    }
  };

  const mouseLeaveHandler = () => {
    setIsDown(false);
    if (menuRef.current) {
      menuRef.current.classList.remove('active');
    }
  };

  const mouseUpHandler = () => {
    setIsDown(false);
    if (menuRef.current) {
      menuRef.current.classList.remove('active');
    }
  };

  const mouseMoveHandler = (e: React.MouseEvent<HTMLUListElement>) => {
    if (!isDown || !menuRef.current) {return;}
    e.preventDefault();
    const x = e.pageX - menuRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    menuRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <section className='questions'>
      <div className='questions__inner'>
        <h2 className='h2 questions__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <div className='questions__main'>
          <ul
            className='questions__nav-list'
            ref={menuRef}
            onMouseDown={mouseDownHandler}
            onMouseLeave={mouseLeaveHandler}
            onMouseUp={mouseUpHandler}
            onMouseMove={mouseMoveHandler}
          >
            { insightsNavList }
          </ul>
          {currentBlock && <QuestionsBlock block={currentBlock} />}
        </div>
      </div>
    </section>
  );
}

export default Questions;
