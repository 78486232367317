import React, { createContext, useContext, useState, useEffect, useCallback, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { BannerContent, NextContent, AbstractContent } from '@models/sections';

interface Data {
  banner: BannerContent;
  next: NextContent;
  why: null;
  abstract: AbstractContent;
  privileges: null;
}

interface Context {
  item: Data;
  getRaiseCapitalPageData: () => Promise<void>;
}

interface RaiseCapitalStore {
  children: PropsWithChildren<JSX.Element>;
}

const RaiseCapitalContext = createContext<Context>({
  item: {
    banner: null,
    next: null,
    why: null,
    abstract: null,
    privileges: null,
  },
  getRaiseCapitalPageData: async () => {
    await new Promise((resolve) => resolve);
    console.log('The Raise Capital page data has not been uploaded!');
  },
});

export const useRaiseCapitalStore = () => useContext(RaiseCapitalContext);

export function RaiseCapitalProvider({ children }: RaiseCapitalStore) {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    banner: null,
    next: null,
    why: null,
    abstract: null,
    privileges: null,
  });

  async function getRaiseCapitalPageData() {
    setIsLoading(true);
    await apiFetch('/wp-json/wp/v2/pages?slug=raisecapital')
      .then((data) => {
        const newData: Data = {
          banner: (data as any)[0].acf.banner,
          next: (data as any)[0].acf.next,
          why: (data as any)[0].acf.why,
          abstract: (data as any)[0].acf.abstract,
          privileges: (data as any)[0].acf.privileges,
        };

        setItem(newData);
      })
      .catch((error) => {
        console.error('Error when uploading data:', error);
      })
      .finally(() => setIsLoading(false));
  }

  const getPostsData = useCallback(async () => {
    const categories = item.abstract?.posts[0].term_id;

    if (item.abstract) {
      item.abstract.cards = [];
    }

    setIsLoading(true);
    categories &&
      await apiFetch(`/wp-json/wp/v2/posts/?categories=${categories}`)
        .then((posts) => {
          (posts as never[]).forEach((post: any) => {
            item.abstract?.cards?.push({
              id: post.id,
              link: post.link,
              title: post.title.rendered,
              text: post.excerpt.rendered,
              post: {
                main: post.acf.banner,
                info: post.acf.advantages,
                links: post.acf.links,
                bannerLink: post.acf.feedback
              }
            });
          });
        })
        .catch()
        .finally(() => {
          setIsLoading(false);
        });
  }, [item]);

  useEffect(() => {
    getRaiseCapitalPageData();
  }, []);

  useEffect(() => {
    getPostsData();
    console.log(item);
  }, [getPostsData, item]);

  return (
    <RaiseCapitalContext.Provider value={{ item, getRaiseCapitalPageData }}>
      {isLoading ? (<div>Loading...</div>) : children}
    </RaiseCapitalContext.Provider>
  );
}
