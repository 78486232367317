import { Route, Routes } from 'react-router-dom';
import { AppRoute } from '@utils/const';

import Layout from '../layout/layout';

import DevelopmentPage from '@pages/development/development';
import NotFoundPage from '@pages/not-found/not-found';

import Main from '@pages/main/main';
import ContactsPage from '@pages/contacts/contacts';
import AboutPage from '@pages/about/about';
import RaiseCapitalPage from '@pages/raise-capital/raise-capital';
import RaiseCapitalPost from '@pages/raise-capital/post/raise-capital-post';
import PrivateEquity from '@pages/private-equity/private-equity';
import PrivateDebt from '@pages/private-debt/private-debt';
import CommoditiesPage from '@pages/commodities/commodities';
import CareersPage from '@pages/careers/careers';
import ServicesPage from '@pages/services/services';
import FeedbackPage from '@pages/feedback/feedback';
import HowItWorksPage from '@pages/how-it-works/how-it-works';
import ResourcesPage from '@pages/resources/resources';

import { LayoutProvider } from '@store/layout';

import { MainProvider } from '@store/main';
import { ResourcesProvider } from '@store/resources';
import { ServicesProvider } from '@store/services';
import { HowItWorksProvider } from '@store/how-it-works';
import { RaiseCapitalProvider } from '@store/raise-capital';
import { RaiseCapitalPostProvider } from '@store/raise-capital-post';
import { AboutProvider } from '@store/about';
import { CareersProvider } from '@store/careers';
import { CommoditiesProvider } from '@store/commodities';
import { PrivateEquityProvider } from '@store/private-equity';
import { PrivateDebtProvider } from '@store/private-debt';

function App(): JSX.Element {

  return (
    <Routes>
      <Route
        path={AppRoute.Main}
        element={<LayoutProvider key={null} type={undefined} props={undefined}><Layout /></LayoutProvider>}
      >
        <Route
          index
          element={<MainProvider key={null} type={undefined} props={undefined}><Main /></MainProvider>}
        />
        <Route
          path={AppRoute.ContactsPage}
          element={<ContactsPage />}
        />
        <Route
          path={AppRoute.AboutPage}
          element={<AboutProvider key={null} type={undefined} props={undefined} ><AboutPage /></AboutProvider>}
        />
        <Route
          path={AppRoute.RaiseCapitalPage}
          element={<RaiseCapitalProvider key={null}><RaiseCapitalPage /></RaiseCapitalProvider>}
        />
        <Route
          path={AppRoute.RaiseCapitalPost}
          element={<RaiseCapitalPostProvider key={null}><RaiseCapitalPost /></RaiseCapitalPostProvider>}
        />
        <Route
          path={AppRoute.PrivateEquity}
          element={<PrivateEquityProvider key={null} type={undefined} props={undefined}><PrivateEquity /></PrivateEquityProvider>}
        />
        <Route
          path={AppRoute.PrivateDebt}
          element={<PrivateDebtProvider key={null} type={undefined} props={undefined}><PrivateDebt /></PrivateDebtProvider>}
        />
        <Route
          path={AppRoute.CommoditiesPage}
          element={<CommoditiesProvider key={null} type={undefined} props={undefined}><CommoditiesPage /></CommoditiesProvider>}
        />
        <Route
          path={AppRoute.CareersPage}
          element={<CareersProvider key={null} type={undefined} props={undefined}><CareersPage /></CareersProvider>}
        />
        <Route
          path={AppRoute.ServicesPage}
          element={<ServicesProvider key={null} type={undefined} props={undefined}><ServicesPage /></ServicesProvider>}
        />
        <Route
          path={AppRoute.FeedbackPage}
          element={<FeedbackPage />}
        />
        <Route
          path={AppRoute.HowItWorksPage}
          element={<HowItWorksProvider key={null} type={undefined} props={undefined}><HowItWorksPage /></HowItWorksProvider>}
        />
        <Route
          path={AppRoute.ResourcesPage}
          element={<ResourcesProvider key={null} type={undefined} props={undefined}><ResourcesPage /></ResourcesProvider>}
        />

        {/* The page for the development status  */}
        <Route
          path={AppRoute.DevelopmentPage}
          element={<DevelopmentPage />}
        />

        {/* The page for the missing page status */}
        <Route
          path={AppRoute.NotFound}
          element={<NotFoundPage />}
        />


        <Route
          path= 'any'
        >
          <Route
            index
            element={<p>any</p>}
          />
          <Route
            path='a'
            element={<p>a</p>}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
