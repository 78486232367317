import type { CareersMainContent } from '@models/sections';
import './careers-main.scss';

interface Props {
  data: CareersMainContent;
}

function CareersMain({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, text, cardsTitle, cards } = data;

  const careersMainList = cards.map((card) => (
    <li key={card.title}>
      <span className='careers-main__item' dangerouslySetInnerHTML={{__html: card.title}}></span>
    </li>
  ));

  return (
    <section className='careers-main'>
      <div className='careers-main__bg'/>
      <div className='careers-main__inner'>
        <div className='careers-main__main'>
          <h1 className='h1 careers-main__title' dangerouslySetInnerHTML={{__html: title}}></h1>
          <p className='careers-main__description' dangerouslySetInnerHTML={{__html: text}}></p>
        </div>
        <div className='careers-main__list-wrap'>
          <h3 className='careers-main__list-title' dangerouslySetInnerHTML={{ __html: cardsTitle }}></h3>
          <ul className='careers-main__list'>
            {careersMainList}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default CareersMain;
