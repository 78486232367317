import BaseSvg from '@components/base/svg/svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import type { IssuanceBlockType, IssuanceBlockNavItemType, IssuanceBlockContentType } from '@models/issuance-content';
import './issuance-block.scss';

interface Props {
  block: IssuanceBlockType;
}

function IssuanceBlock({ block }: Props): JSX.Element {

  const [navListButtons, setNavListButtons] = useState<IssuanceBlockNavItemType[]>([]);
  const [blocksContent, setBlocksContent] = useState<IssuanceBlockContentType[]>([]);
  const [currentContent, setCurrentContent] = useState<IssuanceBlockContentType | null>(null);

  useEffect(() => {
    if (block) {
      const { cards } = block;

      const navList: IssuanceBlockNavItemType[] = [];
      const contentList: IssuanceBlockContentType[] = [];

      cards.forEach((card, index) => {
        navList.push({
          title: card.titlePreview,
          text: card.textPreview,
          active: index === 0
        });

        contentList.push({
          text: card.text,
          imgLarge: card.imgLarge,
          imgMedium: card.imgMedium,
          imgSmall: card.imgSmall
        });
      });

      setNavListButtons(navList);
      setBlocksContent(contentList);
      setCurrentContent(contentList[0]);
    }

  }, [block]);

  if (!block) {
    return <div>There is no data</div>;
  }

  const { title } = block;

  const menu = navListButtons.map((item, index) => {
    const navItemClasses = classNames(
      'issuance-block__nav-item',
      {
        'issuance-block__nav-item--active': item.active
      }
    );

    function handleNavClick(id: number) {
      const updatedNavList = navListButtons.map((button, buttonId) => ({
        ...button,
        active: buttonId === id
      }));
      updatedNavList && setNavListButtons(updatedNavList);

      const targetContent = blocksContent.find((contentItem, contentItemId) => contentItemId === id);

      targetContent && setCurrentContent(targetContent);
    }

    return (
      <li key={item.title} onClick={() => handleNavClick(index)}>
        <div className={navItemClasses}>
          <div className='issuance-block__nav-item-content'>
            {
              item.title &&
              <h4 className='issuance-block__nav-item-title' dangerouslySetInnerHTML={{ __html: item.title }}></h4>
            }
            {
              item.active &&
              <div className='issuance-block__nav-item-text' dangerouslySetInnerHTML={{ __html: item.text }}></div>
            }
          </div>
          <BaseSvg name='arrow-right' />
        </div>
      </li>
    );
  });

  return (
    <div className='issuance-block'>
      <div className='issuance-block__main'>
        <h3 className='issuance-block__title' dangerouslySetInnerHTML={{ __html: title }}></h3>
        <ul className='issuance-block__nav-list'>
          { menu }
        </ul>
      </div>
      {
        currentContent &&
        <div className='issuance-block__content'>
          {
            currentContent?.imgLarge &&
            <picture className='issuance-block__img-wrap'>
              {
                typeof currentContent.imgMedium === 'object' &&
                <source srcSet={currentContent.imgMedium.url}
                  type='image/webp'
                  media='(min-width: 541px) and (max-width: 1024px)'
                />
              }
              {
                typeof currentContent.imgSmall === 'object' &&
                <source srcSet={currentContent.imgSmall.url}
                  type='image/webp'
                  media='(min-width: 320px) and (max-width: 540px)'
                />
              }
              {
                typeof currentContent.imgLarge === 'object' &&
                <img
                  src={currentContent.imgLarge.url}
                  alt={'issuance'}
                />
              }
            </picture>
          }
          <div className='issuance-block__text' dangerouslySetInnerHTML={{ __html: currentContent.text }}></div>
        </div>
      }
    </div>
  );
}

export default IssuanceBlock;
