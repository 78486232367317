import BaseSvg from '@components/base/svg/svg';
import BaseButton from '@components/base/button/button';

import type { PageLinksContent } from '@models/sections';
import './page-links.scss';

interface Props {
  links: PageLinksContent;
}

function PageLinks({ links }: Props): JSX.Element {

  if (!links) {
    return <div>Данных ссылок нет</div>;
  }

  const { next, prev } = links;

  return (
    <div className='page-links'>
      <div className="page-links__inner">
        <BaseButton to={prev.link} classes='page-links__button page-links__button--prev' bordered transparent inlink>
          <BaseSvg name='arrow-left'/>
          <span dangerouslySetInnerHTML={{__html: prev.name}}></span>
        </BaseButton>
        <BaseButton to={next.link} classes='page-links__button page-links__button--next' bordered transparent inlink>
          <BaseSvg name='arrow-right'/>
          <span dangerouslySetInnerHTML={{__html: next.name}}></span>
        </BaseButton>
      </div>
    </div>
  );
}

export default PageLinks;
