import Access from '@components/blocks/access/access';
import Promo from '@components/blocks/promo/promo';
import Alternative from '@components/blocks/alternative/alternative';
import Advantages from '@components/blocks/advantages/advantages';
import Partners from '@components/blocks/partners/partners';
import News from '@components/blocks/news/news';

import { useMainStore } from '@store/main';

import './main.scss';

function Main(): JSX.Element {

  if (!useMainStore) {
    throw new Error('useMainStore must be used within a MainProvider');
  }
  const { item } = useMainStore();

  if (!item) {
    return <div>The Main page data has not been uploaded!</div>;
  }

  return (
    <main className='main'>
      <Promo data={item.promo} />
      <Access data={item.access} />
      <Alternative data={item.alternative} />
      <Advantages data={item.advantages} />
      <News data={item.news} />
      <Partners data={item.partners} />
    </main>
  );
}

export default Main;
