import Question from '../question/question';

import { useState, useEffect } from 'react';

import type { QuestionsBlockType, QuestionType } from '@models/question';
import './questions-block.scss';

interface Props {
  block: QuestionsBlockType;
}

function QuestionsBlock({ block }: Props): JSX.Element {

  const [blockList, setBlockList] = useState<QuestionType[] | null>(null);

  const { cards } = block;

  useEffect(() => {
    if (cards) {
      const cardList = cards.map((card, index) => ({
        title: card.title,
        text: card.text,
        active: index === 0
      }));
      setBlockList(cardList);
    }
  }, [cards]);

  if (!cards) {
    return <div></div>;
  }

  const questionList = blockList?.map((question, index) => (
    <li key={question.title}>
      <Question data={question} onClick={() => handleQuestionClick(index)} />
    </li>
  ));

  const handleQuestionClick = (id: number) => {
    const updatedBlockList = blockList?.map((blockItem, index) => ({
      ...blockItem,
      active: (index === id) && !blockItem.active
    }));
    updatedBlockList && setBlockList(updatedBlockList);
  };

  return (
    <ul className='questions__list'>
      { questionList }
    </ul>
  );
}

export default QuestionsBlock;
