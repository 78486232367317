import BaseButton from '@components/base/button/button';

import { BannerContent } from '@models/sections';
import './banner.scss';

interface Props {
  data: BannerContent;
}


function Banner({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, imgLarge, imgMedium, imgSmall, text, linkName, linkUrl } = data;

  return (
    <section className='banner'>
      <div className='banner__inner'>
        <h1 className='banner__title' dangerouslySetInnerHTML={{ __html: title }}></h1>
        <div className={'banner__text'} dangerouslySetInnerHTML={{ __html: text }}/>
        {
          (linkUrl && linkName) && (
            <BaseButton to={linkUrl} classes='banner__details' outlink>
              <span>{linkName}</span>
            </BaseButton>
          )
        }
      </div>
      {
        imgLarge &&
        <picture className='banner__image'>
          {
            typeof imgMedium === 'object' &&
            <source srcSet={imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof imgSmall === 'object' &&
            <source srcSet={imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          }
          {
            typeof imgLarge === 'object' &&
            <img
              src={imgLarge.url}
              alt={imgLarge.alt}
            />
          }
        </picture>
      }
    </section>
  );
}

export default Banner;
