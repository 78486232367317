import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Analytics from '@components/blocks/analytics/analytics';
import BannerLink from '@components/blocks/banner-link/banner-link';
import Opportunities from '@components/blocks/opportunities/opportunities';
import PageLinks from '@components/blocks/page-link/page-links';

import { usePrivateDebtStore } from '@store/private-debt';
import type { BreadcrumbsItem } from '@types';
import './private-debt.scss';

function PrivateDebt(): JSX.Element {

  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Private debt' }];

  if (!usePrivateDebtStore) {
    throw new Error('usePrivateDebtStore must be used within a PrivateDebtProvider');
  }
  const { item } = usePrivateDebtStore();

  if (!item) {
    return <div>Данные страницы Private Debt не загружены!</div>;
  }

  return (
    <main className='main'>
      <Breadcrumbs breadcrumbs={breadcrumbsList}/>
      <Analytics data={item.analytics} secondary />
      <Opportunities data={item.opportunities} />
      <PageLinks links={item.links} />
      <BannerLink data={item.bannerLink} />
    </main>
  );
}

export default PrivateDebt;
