import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Services from '@components/blocks/services/services';

import type { BreadcrumbsItem } from '@types';

import { useServicesStore } from '@store/services';

import './services.scss';

function ServicesPage(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Services' }];

  if (!useServicesStore) {
    throw new Error('useServicesStore must be used within an ServicesProvider');
  }

  const { item } = useServicesStore();

  if (!item) {
    return <div>The Services page data has not been uploaded!</div>;
  }

  return (
    <main className='main'>
      <Breadcrumbs breadcrumbs={breadcrumbsList} />
      <h1 className='h1 visually-hidden' dangerouslySetInnerHTML={{ __html: item.pageTitle}}></h1>
      <Services data={item.services} />
    </main>
  );
}

export default ServicesPage;
