import type { MeansContent } from '@models/sections';
import './means.scss';

interface Props {
  data: MeansContent;
}

function Means({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, card } = data;

  const meansCardList = card.map((item, index) => {
    const cardList = item.list.map((subitem) => (
      <li key={subitem.title}>
        <div className='means__card-item'>
          <div className='means__card-top'>
            <hr className='means__card-line' />
            <h3 className='means__card-title' dangerouslySetInnerHTML={{ __html: subitem.title }}></h3>
          </div>
          <div className='means__card-text' dangerouslySetInnerHTML={{ __html: subitem.text }}></div>
        </div>
      </li>
    ));

    return (
      // eslint-disable-next-line react/no-array-index-key
      <li key={index}>
        <div className='means__card'>
          {
            item.imgLarge &&
            <picture className='means__card-img-wrap'>
              {
                typeof item.imgMedium === 'object' &&
                <source srcSet={item.imgMedium.url}
                  type='image/webp'
                  media='(min-width: 541px) and (max-width: 1024px)'
                />
              }
              {
                typeof item.imgSmall === 'object' &&
                <source srcSet={item.imgSmall.url}
                  type='image/webp'
                  media='(min-width: 320px) and (max-width: 540px)'
                />
              }
              {
                typeof item.imgLarge === 'object' &&
                <img
                  src={item.imgLarge.url}
                  alt={item.imgLarge.alt}
                />
              }
            </picture>
          }
          <ul className='means__card-list'>
            { cardList }
          </ul>
        </div>
      </li>
    );
  });

  return (
    <section className='means'>
      <div className='means__inner'>
        <h2 className='h2 means__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <ul className='means__content'>
          { meansCardList }
        </ul>
      </div>
    </section>
  );
}

export default Means;
