import BackLink from '@components/blocks/back-link/back-link';
import PageLinks from '@components/blocks/page-link/page-links';
import MiningMain from '@components/blocks/mining-main/mining-main';
import MiningInfo from '@components/blocks/mining-info/mining-info';
import BannerLink from '@components/blocks/banner-link/banner-link';

import { useRaiseCapitalPostStore } from '@store/raise-capital-post';

import './raise-capital-post.scss';

function RaiseCapitalPost(): JSX.Element {

  if (!useRaiseCapitalPostStore) {
    throw new Error('useRaiseCapitalPostStore must be used within a RaiseCapitalProvider');
  }
  const { item } = useRaiseCapitalPostStore();

  if (!item) {
    return <div>The Raise Capital post data has not been uploaded!</div>;
  }

  const backLink = {
    link: '/raise-capital',
    pageName: 'Raise Capital'
  };

  return (
    <main className='main'>
      <BackLink backlink={backLink} />
      <MiningMain data={item.main} />
      <MiningInfo data={item.info} />
      <PageLinks links={item.links} />
      <BannerLink data={item.bannerLink} />
    </main>
  );
}

export default RaiseCapitalPost;
