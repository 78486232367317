import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { ServicesContent } from '@models/sections';

interface Data {
  pageTitle: string;
  services: ServicesContent;
}

interface Context {
  item: Data;
  getServicesPageData: () => Promise<void>;
}

const ServicesContext = createContext<Context>({
  item: {
    pageTitle: '',
    services: null
  },
  getServicesPageData: async () => {
    await new Promise((resolve) => resolve);
    console.log('The Services page data has not been uploaded!');
  },
});

export const useServicesStore = () => useContext(ServicesContext);

export function ServicesProvider({ children }: PropsWithChildren<JSX.Element>) {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    pageTitle: '',
    services: null
  });

  async function getServicesPageData() {
    setIsLoading(true);
    await apiFetch('/wp-json/wp/v2/pages?slug=services')
      .then((data) => {
        const newData: Data = {
            pageTitle: (data as any)[0].acf.pageTitle,
            services: (data as any)[0].acf.services,
        };

        setItem(newData);
      })
      .catch((error) => {
        console.error('Error when uploading data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getServicesPageData();
  }, []);

  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <ServicesContext.Provider value={{ item, getServicesPageData }}>
      {isLoading ? (<div>Loading...</div>) : children}
    </ServicesContext.Provider>
  );
}