import type { StaffCardContent } from '@models/staff-card';

import './staff-card.scss';

interface Props {
  data: StaffCardContent;
}

function StaffCard({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, subTitle, detailText, imgLarge } = data;

  return (
    <div className='staff-card'>
      {
        (imgLarge && typeof imgLarge === 'object') &&
        <img className='staff-card__image' src={imgLarge.url} alt={imgLarge.alt} />
      }
      <h4 className='h4 staff-card__name' dangerouslySetInnerHTML={{ __html: title}}></h4>
      <span className='staff-card__position' dangerouslySetInnerHTML={{ __html: subTitle}}></span>
      <div className='staff-card__description' dangerouslySetInnerHTML={{ __html: detailText}}></div>
    </div>
  );
}

export default StaffCard;
