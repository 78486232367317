import Privilege from './privelege/privilege';

import type { PrivilegesContent } from '@models/sections';
import './privileges.scss';

interface Props {
  data: PrivilegesContent;
}

function Privileges({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, cards } = data;

  const privilegeList = cards.map((card) => (
    <li key={card.title}>
      <Privilege data={card} />
    </li>
  ));

  return (
    <section className='privileges'>
      <div className='privileges__bg'/>
      <div className='privileges__inner'>
        <h2 className='privileges__title' dangerouslySetInnerHTML={{__html: title}}></h2>
        <ul className="privileges__list">
          { privilegeList }
        </ul>
      </div>
    </section>
  );
}

export default Privileges;
