import ReactDOM from 'react-dom/client';
import App from '@components/layout/app/app';
import ScrollToTop from '@components/base/scrolls/scroll-top';
import { BrowserRouter } from 'react-router-dom';

import { ModalsProvider } from '@store/modals';
/* import './scss/styles.scss'; */

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <ModalsProvider key={null} type={undefined} props={undefined}>
      <ScrollToTop />
      <App />
    </ModalsProvider>
  </BrowserRouter>
);
