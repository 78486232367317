import Modal from '@components/base/modal/modal';

import type { NewsLinkModalContent } from '@models/news-link';
import './news-link-modal.scss';

interface Props {
  data: NewsLinkModalContent;
  isOpened: boolean;
  onClose: () => void;
}

function NewsLinkModal({ data, isOpened, onClose }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { imgLarge, title, text } = data;

  return (
    <Modal isOpened={isOpened} onClose={onClose}>
      <article className='m-news'>
        <p className='m-news__title' dangerouslySetInnerHTML={{ __html: title }} ></p>
        <div className='m-news__info'>
          {
            imgLarge &&
            <picture className='m-news__image'>
              {/* {
                typeof imgMedium === 'object' &&
                <source srcSet={imgMedium.url}
                  type='image/webp'
                  media='(min-width: 541px) and (max-width: 1024px)'
                />
              }
              {
                typeof imgSmall === 'object' &&
                <source srcSet={imgSmall.url}
                  type='image/webp'
                  media='(min-width: 320px) and (max-width: 540px)'
                />
              } */}
              {
                typeof imgLarge === 'object' &&
                <img
                  src={imgLarge.url}
                  alt={'m-news__image'}
                />
              }
            </picture>
          }
          {/* <video src='/img/resources/popup-img.png' className='m-news__video'></video> */}
          <div className='m-news__text-wrap'>
            <div className='m-news__text' dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </div>
      </article>
    </Modal>
  );
}

export default NewsLinkModal;
