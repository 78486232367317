import BaseSvg from '@components/base/svg/svg';
import classNames from 'classnames';
import { AlternativeContent } from '@models/sections';

import './alternative.scss';

interface Props {
  data: AlternativeContent;
}

function Alternative({ data }: Props): JSX.Element {
  if (!data) {
    return <div>There is no data</div>;
  }

  const { imgLarge, title, cards } = data;

  const iconClasses = classNames(
    'alternative__item-icon'
  );

  const sectionClasses = classNames(
    'alternative',
    /* { 'alternative--secondary': data.secondary } */
  );

  const alternativeContent = cards.map((item) => {
    const TopWrapper = item.link ? 'a' : 'div';

    return (
      <li className={'alternative__list-item'} key={item.title}>
        <div className='alternative__item'>
          <div className='alternative__item-inner'>
            <TopWrapper
              className='alternative__item-top'
              href={item.link || undefined}
              target={item.link ? '_blank' : undefined}
              rel={item.link ? 'noopener noreferrer' : undefined}
            >
              <h3 className='h3 alternative__item-title'>{item.title}</h3>
              {item.link && <BaseSvg name={'arrow-right-top'} classes={iconClasses} />}
            </TopWrapper>
            {item.text && <p className='alternative__item-text' dangerouslySetInnerHTML={{ __html: item.text }} />}
            {item.textAsterism && <p className='alternative__item-source' dangerouslySetInnerHTML={{ __html: item.textAsterism }} />}
          </div>
        </div>
      </li>
    );
  });

  return (
    <section className={sectionClasses}>
      {/* <div className={'alternative__bg'}/> */}
      <div className='alternative__inner'>
        <h2 className='h2 alternative__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <div className='alternative__content'>
          <ul className='alternative__list'>
            {alternativeContent}
          </ul>
        </div>
      </div>
      {/* {
        imgLarge &&
        <picture>
          {
            typeof imgMedium === 'object' &&
            <source srcSet={imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof imgSmall === 'object' &&
            <source srcSet={imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          }
          {
            typeof imgLarge === 'object' &&
            <img
              className='alternative__image'
              src={imgLarge.url}
              alt={imgLarge.alt}
            />
          }
        </picture>
      } */}
    </section>
  );
}

export default Alternative;
