import BaseButton from '@components/base/button/button';
import BaseSvg from '@components/base/svg/svg';
import InsightModal from '@components/blocks/modals/insight-modal/insight-modal';

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import type { InsightsContent } from '@models/sections';
import { useModal } from '@store/modals';

import Insight from './insight/insight';
import './insights.scss';

interface Props {
  data: InsightsContent;
}

function Insights({ data }: Props): JSX.Element {

  const { open, render } = useModal(InsightModal);

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, cards } = data;

  const insightList = cards.map((card) => {

    const modalContent = {
      title: card.title,
      text: card.text,
      imgLarge: card.imgLarge
    };

    return (
      <SwiperSlide
        tag='li'
        key={card.title}
      >
        <Insight data={card} onClick={modalContent ? () => handleOpenModal(modalContent as never) : undefined} />
      </SwiperSlide>
    );
  });

  const handleOpenModal = (modalContent: never) => {
    render(modalContent);
    open();
  };

  return (
    <section className='insights'>
      <div className='insights__inner'>
        <div className='insights__bg'></div>
        <div className='insights__top'>
          <h2 className='h2 insights__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
          <div className='insights__buttons'>
            <BaseButton classes='insights__button insights__button-prev'>
              <BaseSvg name='arrow-left' />
            </BaseButton>
            <BaseButton classes='insights__button insights__button-next'>
              <BaseSvg name='arrow-right' />
            </BaseButton>
          </div>
        </div>
        <Swiper
          className='insights__slider'
          wrapperTag='ul'
          wrapperClass='insights__list'
          modules={[Pagination, Navigation]}
          navigation={
            {
              nextEl: '.insights__button-next',
              prevEl: '.insights__button-prev',
              disabledClass: 'insights__button--disabled'
            }
          }
          pagination={
            {
              el: '.insights__pagination-wrapper',
              clickable: true,
              bulletClass: 'insights__pagination-bullet',
              bulletActiveClass: 'insights__pagination-bullet-active'
            }
          }
          slidesPerView={2}
          spaceBetween={32}
          speed={500}
          mousewheel
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 24
            },
            744: {
              slidesPerView: 2,
              spaceBetween: 32
            },
          }}
        >
          { insightList }
        </Swiper>
        <div className='insights__pagination-wrapper'/>
        {/* <ul className='insights__list'>
          { insightList }
        </ul> */}
      </div>
    </section>
  );
}

export default Insights;
