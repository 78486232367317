import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { NewsLinksContent, InsightsContent, QuestionsContent } from '@models/sections';

interface Data {
    pageTitle: string;
    newsLinks: NewsLinksContent;
    insights: InsightsContent;
    faq: QuestionsContent;
}

interface Context {
  item: Data;
  getResourcesPageData: () => Promise<void>;
}

const ResourcesContext = createContext<Context>({
  item: {
    pageTitle: '',
    newsLinks: null,
    insights: null,
    faq: null
  },
  getResourcesPageData: async () => {
    await new Promise((resolve) => resolve);
    console.log('The Resources page data has not been uploaded!');
  },
});

export const useResourcesStore = () => useContext(ResourcesContext);

export function ResourcesProvider({ children }: PropsWithChildren<JSX.Element>) {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    pageTitle: '',
    newsLinks: null,
    insights: null,
    faq: null
  });

  async function getResourcesPageData() {
    setIsLoading(true);
    await apiFetch('/wp-json/wp/v2/pages?slug=resources')
      .then((data) => {
        const newData: Data = {
          pageTitle: (data as any)[0].acf.pageTitle,
          newsLinks: (data as any)[0].acf.news,
          insights: (data as any)[0].acf.insights,
          faq: (data as any)[0].acf.faq
        };

        setItem(newData);
      })
      .catch((error) => {
        console.error('Error when uploading data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getResourcesPageData();
  }, []);

  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <ResourcesContext.Provider value={{ item, getResourcesPageData }}>
      {isLoading ? (<div>Loading...</div>) : children}
    </ResourcesContext.Provider>
  );
}