import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { CareersMainContent, VacanciesContent, BannerLinkContent } from '@models/sections';

interface Data {
  main: CareersMainContent;
  vacancies: VacanciesContent;
  bannerLink: BannerLinkContent;
}

interface Context {
  item: Data;
  getCareersPageData: () => Promise<void>;
}

const CareersContext = createContext<Context>({
  item: {
    main: null,
    vacancies: null,
    bannerLink: null,
  },
  getCareersPageData: async () => {
    await new Promise((resolve) => resolve);
    console.log('The Careers page data has not been uploaded!');
  },
});

export const useCareersStore = () => useContext(CareersContext);

export function CareersProvider({ children }: PropsWithChildren<JSX.Element>) {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    main: null,
    vacancies: null,
    bannerLink: null,
  });

  async function getCareersPageData() {
    setIsLoading(true);
    await apiFetch('/wp-json/wp/v2/pages?slug=careers')
      .then((data) => {
        const newData: Data = {
          main: (data as any)[0].acf.banner,
          vacancies: (data as any)[0].acf.team,
          bannerLink: (data as any)[0].acf.feedback,
        };

        setItem(newData);
      })
      .catch((error) => {
        console.error('Error when uploading data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getCareersPageData();
  }, []);

  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <CareersContext.Provider value={{ item, getCareersPageData }}>
      {isLoading ? (<div>Loading...</div>) : children}
    </CareersContext.Provider>
  );
}
