import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { StoryContent, StaffContent } from '@models/sections';

interface Data {
  story: StoryContent;
  staff: StaffContent;
}

interface Context {
  item: Data;
  getAboutPageData: () => Promise<void>;
}

const AboutContext = createContext<Context>({
  item: {
    story: null,
    staff: null,
  },
  getAboutPageData: async () => {
    await new Promise((resolve) => resolve);
    console.log('The About Us page data has not been uploaded!');
  },
});

export const useAboutStore = () => useContext(AboutContext);

export function AboutProvider({ children }: PropsWithChildren<JSX.Element>) {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    story: null,
    staff: null,
  });

  async function getAboutPageData() {
    setIsLoading(true);
    await apiFetch('/wp-json/wp/v2/pages?slug=about')
      .then((data) => {
        const newData: Data = {
          story: (data as any)[0].acf.about,
          staff: (data as any)[0].acf.staff,
        };

        setItem(newData);
      })
      .catch((error) => {
        console.error('Error when uploading data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getAboutPageData();
  }, []);

  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <AboutContext.Provider value={{ item, getAboutPageData }}>
      {isLoading ? (<div>Loading...</div>) : children}
    </AboutContext.Provider>
  );
}
