import type { RaiseCapitalPostMainContent } from '@models/sections';
import './mining-main.scss';

interface Props {
  data: RaiseCapitalPostMainContent;
}

function MiningMain({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, text, imgLarge, imgMedium, imgSmall } = data;

  return (
    <section className='mining-main'>
      <div className='mining-main__inner'>
        <div className="mining-main__content">
          <h1 className='mining-main__title' dangerouslySetInnerHTML={{ __html: title}}></h1>
          <div className="mining-main__text" dangerouslySetInnerHTML={{ __html: text}}></div>
        </div>
        {
          imgLarge &&
          <picture className='mining-main__image'>
            {
              typeof imgMedium === 'object' &&
              <source srcSet={imgMedium.url}
                type='image/webp'
                media='(min-width: 768px) and (max-width: 1440px)'
              />
            }
            {
              typeof imgSmall === 'object' &&
              <source srcSet={imgSmall.url}
                type='image/webp'
                media='(min-width: 320px) and (max-width: 768px)'
              />
            }
            {
              typeof imgLarge === 'object' &&
              <img
                src={imgLarge.url}
                alt={imgLarge.alt}
              />
            }
          </picture>
        }
      </div>
    </section>
  );
}

export default MiningMain;
