import Abstract from '@components/blocks/abstract/abstract';
import Privileges from '@components/blocks/privileges/privileges';
import Advantages from '@components/blocks/advantages/advantages';
import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Banner from '@components/blocks/banner/banner';
import Next from '@components/blocks/next/next';
import Why from '@components/blocks/why/why';

import { useRaiseCapitalStore } from '@store/raise-capital';
import type { BreadcrumbsItem } from '@types';
import './raise-capital.scss';

function RaiseCapitalPage(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Raise Capital' }];

  if (!useRaiseCapitalStore) {
    throw new Error('useRaiseCapitalStore must be used within a RaiseCapitalProvider');
  }
  const { item } = useRaiseCapitalStore();

  if (!item) {
    return <div>The Raise Capital page data has not been uploaded!</div>;
  }

  return (
    <main className='main'>
      <Breadcrumbs breadcrumbs={breadcrumbsList}/>
      <Banner data={item.banner}/>
      <Next data={item.next}/>
      <Why data={item.why}/>
      <Abstract data={item.abstract} />
      <Privileges data={item.privileges} />
      {/* <Advantages data={RaiseCapitalData.advantages} /> */}
    </main>
  );
}

export default RaiseCapitalPage;
