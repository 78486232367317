import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

import { AdvantagesContent } from '@models/sections';
import './advantages.scss';

interface Props {
  data: AdvantagesContent;
}

function Advantages({ data }: Props): JSX.Element {

  if (!data) {
    return <div>There is no data</div>;
  }

  const { imgLarge, imgMedium, imgSmall, title, text, cards } = data;

  const classes = classNames(
    'advantages',
    /* {'advantages--secondary': data.secondary} */
  );

  const advantagesContent = cards.map((item) => (
    <SwiperSlide
      tag='li'
      key={item.title}
    >
      <div className='advantages__item'>
        <h3 className='advantages__item-title'>{ item.title }</h3>
        <p className='advantages__item-text' dangerouslySetInnerHTML={{__html: item.text}}></p>
      </div>
    </SwiperSlide>
  ));

  return (
    <section className={classes}>
      <div className='advantages__inner'>
        <h2 className='h2 advantages__title'>{ title }</h2>
        {
          text &&
          <div className={'advantages__text'} dangerouslySetInnerHTML={{__html: text}}></div>
        }
        <Swiper
          className='advantages__content'
          wrapperTag='ul'
          wrapperClass='advantages__list'
          slidesPerView={3}
          spaceBetween={42}
          speed={500}
          mousewheel
          breakpoints={{
            320: {
              slidesPerView: 1.1,
              spaceBetween: 24
            },
            641: {
              slidesPerView: 1.5,
              spaceBetween: 42
            },
            1025: {
              slidesPerView: 2.25
            },
            1281: {
              slidesPerView: 3
            }
          }}
        >
          { advantagesContent }
        </Swiper>
      </div>
      {
        imgLarge &&
        <picture>
          {
            typeof imgMedium === 'object' &&
            <source srcSet={imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof imgSmall === 'object' &&
            <source srcSet={imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          }
          {
            typeof imgLarge === 'object' &&
            <img
              className='promo__image'
              src={imgLarge.url}
              alt={imgLarge.alt}
            />
          }
        </picture>
      }
    </section>
  );
}

export default Advantages;
