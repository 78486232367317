import { useState, useEffect } from 'react';

import StaffCardModal from '@components/blocks/modals/staff-modal/staff-modal';

import type { StaffContent } from '@models/sections';
import type { StaffCardModalContent } from '@models/staff-card';

import { useModal } from '@store/modals';

import StaffCard from './card/staff-card';
import './staff.scss';

interface Props {
  data: StaffContent;
}

function Staff({ data }: Props): JSX.Element {

  const [staffCards, setStaffCards] = useState<JSX.Element[]>([]);
  const { open, render } = useModal(StaffCardModal);

  useEffect(() => {
    if(data) {
      const { cards } = data;
      if (cards && Array.isArray(cards) && cards.length > 0) {
        const staffList = cards.map((card) => {
          const modalContent: StaffCardModalContent = {
            imgLarge: card.imgLarge,
            title: card.title,
            subTitle: card.subTitle,
            detailText: card.detailText
          };

          return (
            <li key={card.title} className='staff__item' onClick={() => handleOpenModal(modalContent as never)}>
              <StaffCard data={card} ></StaffCard>
            </li>
          );
        });
        setStaffCards(staffList);
      }
    }
  }, [data]);

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title } = data;

  const handleOpenModal = (modalContent: never) => {
    render(modalContent);
    open();
  };

  return (
    <section className='staff'>
      <div className='staff__inner'>
        <h2 className="h2 staff__title" dangerouslySetInnerHTML={{ __html: title }}></h2>
        <ul className="staff__list">
          { staffCards }
        </ul>
      </div>
    </section>
  );
}

export default Staff;
