import Banner from '@components/blocks/banner/banner';
import Means from '@components/blocks/means/means';
import Issuance from '@components/blocks/issuance/issuance';

import { useHowItWorksStore } from '@store/how-it-works';
import './how-it-works.scss';

function HowItWorksPage(): JSX.Element {

  if (!useHowItWorksStore) {
    throw new Error('useHowItWorksStore must be used within a HowItWorksProvider');
  }
  const { item } = useHowItWorksStore();

  if (!item) {
    return <div>The How It Works page data has not been uploaded!</div>;
  }

  return (
    <main className='main'>
      <Banner data={item.banner} />
      <Means data={item.means} />
      <Issuance data={item.issuance} />
    </main>
  );
}

export default HowItWorksPage;
