import Logo from '@components/base/logo/logo';
import { NavLink } from 'react-router-dom';

import BlockSocials from '@components/blocks/socials/socials';

import type { FooterNavContent } from '@models/layout';
import './footer.scss';

interface Props {
  nav: FooterNavContent;
}

function Footer({ nav }: Props): JSX.Element {

  if (!nav) {
    return <div>There is no data</div>;
  }

  const listItems = nav.map(({ title, link, list }) => {
    const sublistItems = list?.map((subitem) => (
      <li key={subitem.title}>
        {subitem.link.startsWith('http://') || subitem.link.startsWith('https://') ? (
          <a href={subitem.link} target="_blank" rel="noopener noreferrer">
            {subitem.title}
          </a>
        ) : (
          <NavLink to={subitem.link} className="footer__sublist-item">
            {subitem.title}
          </NavLink>
        )}
      </li>
    ));

    return (
      <li key={title}>
        <NavLink to={link} className="footer__list-item">{title}</NavLink>
        {list && (
          <ul className="footer__sublist">
            {sublistItems}
          </ul>
        )}
      </li>
    );
  }) || [];

  return (
    <footer className='footer'>
      <div className='footer__inner'>
        <div className="footer__content">
          <div className='footer__main'>
            <Logo className='footer__logo'/>
            <BlockSocials />
          </div>
          <nav className='footer__nav'>
            <ul className='footer__list'>
              { listItems }
            </ul>
          </nav>
        </div>
        <div className="footer__bottom">
          <span className="footer__copyright">Copyright © { new Date().getFullYear() } ATME. All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
