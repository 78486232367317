import Logo from '@components/base/logo/logo';
import Menu from '@components/base/menu/menu';
import BaseButton from '@components/base/button/button';

import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { headerActions } from '@data';
import type { HeaderNavContent } from '@models/layout';
import './header.scss';

interface Props {
  nav: HeaderNavContent;
}

function Header({ nav }: Props): JSX.Element {
  const [menuState, setMenuState] = useState<boolean>(false);

  if (!nav) {
    return <div>There is no data</div>;
  }

  const menuClasses = classNames(
    'header__menu',
    `header__menu--${menuState ? 'opened' : 'closed'}`,
  );

  const onMenuClick = (): void => {
    setMenuState(!menuState);
  };

  const listItems = nav.map(({ title, link, list }) => (
    <li key={title}>
      <NavLink to={link} className={({ isActive }) => isActive ? 'header__list-item active' : 'header__list-item'}>{title}</NavLink>
      {
        list && (
          <ul className='header__sublist'>
            {
              list.map((item) => (
                <li key={item.title}>
                  <NavLink to={item.link} className={({ isActive }) => isActive ? 'header__sublist-item active' : 'header__sublist-item'}>{item.title}</NavLink>
                </li>
              ))
            }
          </ul>
        )
      }
    </li>
  ));

  const actionsItems = headerActions.map(({ title, to, href, primary, secondary }) => {
    if (to) {
      return (
        <li key={title} >
          <BaseButton to={to} classes='header__action-item' inlink primary={primary} secondary={secondary}>
            <span>{title}</span>
          </BaseButton>
        </li>
      );
    } else if (href) {
      return (
        <li key={title} >
          <BaseButton to={href} classes='header__action-link' outlink primary={primary} secondary={secondary}>
            <span>{title}</span>
          </BaseButton>
        </li>
      );
    } else {
      // Возвращаем пустой элемент или другое значение по умолчанию
      return <li key={title} className='contacts__item'>The header action has an unknown link type</li>;
    }
  });

  return (
    <header className={`header ${menuState ? '_opened' : ''}`}>
      <div className='header__inner'>
        <Logo className='header__logo'/>
        <nav className='header__nav'>
          <ul className='header__list'>
            {listItems}
          </ul>
          <ul className='header__actions'>
            {actionsItems}
          </ul>
        </nav>
        <BaseButton classes={menuClasses} withoutPadding transparent onClick={onMenuClick}>
          <span></span>
        </BaseButton>
      </div>
      <Menu onMenuClick={onMenuClick}/>
    </header>
  );
}

export default Header;
