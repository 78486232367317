import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import { useState, useEffect } from 'react';

import BaseSvg from '@components/base/svg/svg';
import BaseButton from '@components/base/button/button';
import Vacancy from './vacancy/vacancy';

import type { VacanciesContent } from '@models/sections';
import './vacancies.scss';

interface Props {
  data: VacanciesContent;
}

function Vacancies({ data }: Props): JSX.Element {

  const [vacancyList, setVacancyList] = useState<JSX.Element[]>();

  useEffect(() => {
    if (data) {
      const { cards } = data;
      if (cards && Array.isArray(cards) && cards.length > 0) {
        const currentList = cards.map((card) => (
          <SwiperSlide
            tag='li'
            key={card.title}
            className='vacancies__item'
          >
            <Vacancy data={card} />
          </SwiperSlide>
        ));
        setVacancyList(currentList);
      }
    }
  }, [data]);

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title, cards } = data;

  return (
    <section className='vacancies'>
      { cards.length > 0 ? (
        <div className='vacancies__inner'>
          <div className="vacancies__top">
            <h2 className="h2 vacancies__title" dangerouslySetInnerHTML={{ __html: title }}></h2>
            <div className="vacancies__buttons">
              <BaseButton classes="vacancies__button vacancies__button-prev">
                <BaseSvg name="arrow-left" />
              </BaseButton>
              <BaseButton classes="vacancies__button vacancies__button-next">
                <BaseSvg name="arrow-right" />
              </BaseButton>
            </div>
          </div>
          <div className="vacancies__bottom">
            <Swiper
              className='vacancies__slider'
              wrapperTag='ul'
              wrapperClass='vacancies__list'
              modules={[Pagination, Navigation]}
              navigation={{
                nextEl: '.vacancies__button-next',
                prevEl: '.vacancies__button-prev',
                disabledClass: 'vacancies__button--disabled'
              }}
              pagination={{
                el: '.vacancies__pagination-wrapper',
                clickable: true,
                bulletClass: 'vacancies__pagination-bullet',
                bulletActiveClass: 'vacancies__pagination-bullet-active'
              }}
              slidesPerView={3}
              spaceBetween={32}
              speed={500}
              mousewheel
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                769: {
                  slidesPerView: 1.5,
                },
                1025: {
                  slidesPerView: 2
                },
                1441: {
                  slidesPerView: 3
                }
              }}
            >
              {vacancyList}
            </Swiper>
            <div className='vacancies__pagination-wrapper' />
          </div>
        </div>
      ) : (
        <div className='vacancies__inner vacancies__inner--empty'>
          <div className='vacancies__top'>
            <h2 className='h2 vacancies__title' dangerouslySetInnerHTML={{ __html: 'No Opportunities Available' }}></h2>
          </div>
          <div className='vacancies__bottom'>
            <p className='vacancies__text'>Stay tuned; opportunities are just around the corner.</p>
          </div>
        </div>
      )}
    </section>
  );
}

export default Vacancies;
