import Commodity from './commodity/commodity';

import type { CommoditiesContent } from '@models/sections';
import './commodities.scss';

interface Props {
  data: CommoditiesContent;
}

function Commodities({ data }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title, cards } = data;

  const commoditiesContent = cards.map((card, index) => (
    <Commodity data={card} key={card?.title} reversed={ index % 2 !== 0 } />
  ));

  return (
    <section className='commodities'>
      <div className='commodities__inner'>
        <h1 className='commodities__title' dangerouslySetInnerHTML={{ __html: title}}></h1>
        <ul className='commodities__list'>
          { commoditiesContent }
        </ul>
      </div>
    </section>
  );
}

export default Commodities;
