import classNames from 'classnames';

import type { IssuanceStepButtonType } from '@models/issuance-content';
import './issuance-step.scss';

interface Props {
  step: IssuanceStepButtonType;
  onClick: () => void;
}

function IssuanceStep({ step, onClick }: Props): JSX.Element {

  const { number, icon, title, active } = step;

  const classes = classNames(
    'issuance-step',
    {
      'issuance-step--active': active
    }
  );

  return (
    <div className={classes} onClick={onClick}>
      <span className='issuance-step__number' dangerouslySetInnerHTML={{ __html: number}}></span>
      {
        icon &&
        <picture className='issuance-step__image'>
          {
            typeof icon === 'object' &&
            <img
              src={icon.url}
              alt={icon.alt}
            />
          }
        </picture>
      }
      <div className='issuance-step__title' dangerouslySetInnerHTML={{ __html: title}}></div>
    </div>
  );
}

export default IssuanceStep;
