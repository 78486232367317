import classNames from 'classnames';

import type { CommoditiesCard } from '@models/commodities-card';
import './commodity.scss';

interface Props {
  data: CommoditiesCard;
  reversed?: boolean;
}

function Commodity({ data, reversed = false }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title, text, imgLarge, imgMedium, imgSmall, cards } = data;

  const classes = classNames(
    'commodity',
    {
      'commodity--reversed': reversed
    }
  );

  const commodityContent = cards.map((card) => (
    <li className='commodity__item' key={card.text}>
      <div className='commodity__text' dangerouslySetInnerHTML={{ __html: card.text}}></div>
      {card.textAsterism && <div className='commodity__source' dangerouslySetInnerHTML={{ __html: card.textAsterism }}></div>}
    </li>
  ));

  return (
    <li className={classes}>
      {
        imgLarge &&
        <picture className='commodity__image'>
          {/* {
            typeof imgMedium === 'object' &&
            <source srcSet={imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof imgSmall === 'object' &&
            <source srcSet={imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          } */}
          {
            typeof imgLarge === 'object' &&
            <img
              src={imgLarge.url}
              alt={imgLarge.alt}
            />
          }
        </picture>
      }
      <div className='commodity__content'>
        <div className='commodity__top'>
          <h3 className='h3 commodity__title' dangerouslySetInnerHTML={{ __html: title }}></h3>
          <div className='commodity__description' dangerouslySetInnerHTML={{__html: text}}></div>
        </div>
        <ul className='commodity__list'>
          { commodityContent }
        </ul>
      </div>
    </li>
  );
}

export default Commodity;
