import Contacts from '@components/blocks/contacts/contacts';
import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import type { BreadcrumbsItem } from '@types';

import './contacts.scss';

function ContactsPage(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Contact Us' }];

  return (
    <main className='main'>
      <Breadcrumbs breadcrumbs={breadcrumbsList}/>
      <Contacts />
    </main>
  );
}

export default ContactsPage;
