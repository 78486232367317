import classNames from 'classnames';

import type { AnalyticsContent } from '@models/sections';
import './analytics.scss';

interface Props {
  data: AnalyticsContent;
  secondary?: boolean;
}

function Analytics({ data, secondary }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title, text, scheduleText, scheduleAsterism, imgLarge, imgMedium, imgSmall, info, scheduleAdditional } = data;

  const classes = classNames(
    'analytics',
    {'analytics--secondary': secondary}
  );

  return (
    <section className={classes}>
      <div className='analytics__inner'>
        <h1 className='analytics__title' dangerouslySetInnerHTML={{__html: title}}></h1>
        <p className='analytics__description' dangerouslySetInnerHTML={{__html: text}}></p>
        <div className='analytics__schedule'>
          <div className='analytics__schedule-content'>
            <div className='analytics__schedule-description' dangerouslySetInnerHTML={{__html: scheduleText}}></div>
            { scheduleAsterism && <span className='analytics__schedule-source' dangerouslySetInnerHTML={{__html: scheduleAsterism}}></span> }
          </div>
          {
            imgLarge &&
            <picture className='analytics__image'>
              {
                typeof imgMedium === 'object' &&
                <source srcSet={imgMedium.url}
                  type='image/webp'
                  media='(min-width: 541px) and (max-width: 1024px)'
                />
              }
              {
                typeof imgSmall === 'object' &&
                <source srcSet={imgSmall.url}
                  type='image/webp'
                  media='(min-width: 320px) and (max-width: 540px)'
                />
              }
              {
                typeof imgLarge === 'object' &&
                <img
                  src={imgLarge.url}
                  alt={imgLarge.alt}
                />
              }
            </picture>
          }
        </div>
        <p className='analytics__info' dangerouslySetInnerHTML={{__html: info}}></p>
        { scheduleAdditional && <p className='analytics__info-additional' dangerouslySetInnerHTML={{__html: scheduleAdditional}}></p>}
      </div>
    </section>
  );
}

export default Analytics;
