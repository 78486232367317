import DevelopmentMain from '@components/blocks/development/development';

import './development.scss';

function DevelopmentPage(): JSX.Element {

  return (
    <main className='main'>
      <DevelopmentMain />
    </main>
  );
}

export default DevelopmentPage;
