import { useState, useEffect } from 'react';

import IssuanceStep from './step/issuance-step';
import IssuanceBlock from './block/issuance-block';

import type { IssuanceContent } from '@models/sections';
import type { IssuanceStepButtonType, IssuanceBlockType } from '@models/issuance-content';
import './issuance.scss';

interface Props {
  data: IssuanceContent;
}

function Issuance({ data }: Props): JSX.Element {

  const [stepButtons, setStepButtons] = useState<IssuanceStepButtonType[]>([]);
  const [blocks, setBlocks] = useState<IssuanceBlockType[]>([]);
  const [currentBlock, setCurrentBlock] = useState<IssuanceBlockType | null>(null);

  useEffect(() => {
    if (data) {
      const { steps } = data;
      const stepsData: IssuanceStepButtonType[] = [];
      const blocksData: IssuanceBlockType[] = [];

      steps.forEach((step, index) => {
        stepsData.push({
          number: step.number,
          icon: step.icon,
          title: step.title,
          active: index === 0
        });

        blocksData.push({
          title: step.cardTitle,
          cards: step.cards,
        });
      });
      setStepButtons(stepsData);
      setBlocks(blocksData);
      setCurrentBlock(blocksData[0]);
    }
  }, [data]);

  if (!data) {
    return <div>There is no data</div>;
  }

  const { title } = data;

  const stepList = stepButtons?.map((step, index) => {
    const lastElement = (stepButtons.length - 1) === index;

    return (
      <li key={step.title}>
        <IssuanceStep step={step} onClick={() => handleStepClick(index)} />
        {
          !lastElement &&
          <hr className='issuance__steps-line' />
        }
      </li>
    );
  });

  const handleStepClick = (id: number) => {
    const updatedSteps = stepButtons?.map((step, index) => ({
      ...step,
      active: index === id
    }));
    updatedSteps && setStepButtons(updatedSteps);

    const targetBlock = blocks?.find((block, blockId) => blockId === id);
    targetBlock && setCurrentBlock(targetBlock);
  };

  return (
    <section className='issuance'>
      <div className='issuance__inner'>
        <div className='issuance__top'>
          <h2 className='h2 issuance__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
          <div className='issuance__bg'></div>
        </div>
        <div className='issuance__content'>
          <ul className='issuance__steps'>
            { stepList }
          </ul>
          {currentBlock && <IssuanceBlock block={currentBlock} />}
        </div>
      </div>
    </section>
  );
}

export default Issuance;
